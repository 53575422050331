import React, { FC, useMemo } from 'react'
import moment from 'moment-timezone'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

type Props = {
  value: string
  onChange: (value: string) => void
}

const getTimezonesSelectData = () => {
  const timeZones = moment.tz.names()

  return timeZones.map((tz) => ({
    value: tz,
    label: `UTC ${moment.tz(tz).format('Z')}: ${tz}`,
  }))
}

export const TimeZoneSelect: FC<Props> = ({ value, onChange }) => {
  const timezones = useMemo(getTimezonesSelectData, [])

  return (
    <Box mb={2}>
      <Autocomplete
        value={timezones.find((tz) => tz.value === value) || null}
        onChange={(_, val) => onChange(val?.value || '')}
        options={timezones}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Timezone" variant="outlined" />}
      />
    </Box>
  )
}
