// Core
import React, { FC, useMemo } from 'react'
import { Box } from '@material-ui/core'
// hooks
import useDateUtils from 'core/date/use-date-utils'

type Props = {
  lastUpdatedAt?: string
  lastPublishedAt?: string
  originalCreatedAt?: string
}

type Data = {
  updatedAt: null | string
  publishedAt: null | string
}

const UpdatedDates: FC<Props> = ({ lastUpdatedAt, lastPublishedAt, originalCreatedAt }) => {
  const { formateDate } = useDateUtils()

  const data = useMemo(() => {
    const data: Data = {
      updatedAt: null,
      publishedAt: null,
    }

    if (lastUpdatedAt) {
      data.updatedAt = formateDate(lastUpdatedAt)
    }

    if (lastPublishedAt && lastPublishedAt !== originalCreatedAt) {
      data.publishedAt = formateDate(lastPublishedAt)
    }

    return data
  }, [formateDate, lastPublishedAt, lastUpdatedAt, originalCreatedAt])

  if (!data.updatedAt && !data.publishedAt) return null

  return (
    <Box
      width="100%"
      pt={1}
      mt={1}
      display="flex"
      flexDirection="column"
      gridRowGap={4}
      style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
    >
      {data.updatedAt && (
        <Box>
          Last Updated: <b>{data.updatedAt}</b>
        </Box>
      )}
      {data.publishedAt && (
        <Box>
          Last Published: <b>{data.publishedAt}</b>
        </Box>
      )}
    </Box>
  )
}
export default UpdatedDates
