import React, { FC } from 'react'
import { ResourceProps } from 'core/data'
import { Edit } from 'core/data'
import { FormContainer } from '../index'
import { EntityType } from 'core/types'

const WidgetTypeEdit: FC<ResourceProps> = (props) => {
  const transformData = (entityType: EntityType) => {
    let { options } = entityType

    if (Array.isArray(options)) {
      options = {}
    }

    return {
      ...entityType,
      options,
    }
  }

  return <Edit {...props} crudForm={FormContainer} transformData={transformData} />
}

export default WidgetTypeEdit
