// Core
import React, { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import debounce from 'lodash.debounce'
// Components
import { DataGridHeader } from 'ui'
import KandySync from './kandy-sync'
// Hooks
import { useDataViewContext } from './data-view-context'
import { useAppContext, Permissions } from 'core/app'
import { useSitesContext } from 'modules/sites'
import { BulkActionsButton } from './bulk-actions/bulk-actions-button'

type Props = {
  children?: never
}

const TableHeader: FC<Props> = () => {
  const { isDataFetching } = useDataViewContext()
  const { entityType, typeId, search, setSearch } = useDataViewContext()
  const { slug } = entityType
  const {
    actions: { userCan },
  } = useAppContext()

  const canCreate = userCan(entityType.slug, Permissions.CREATE)
  const createPath = `/entities/${typeId}/create`

  const [s, setS] = useState(search)

  const submitHandler = useCallback(
    (e: FormEvent) => {
      setSearch(s)
      e.preventDefault()
    },
    [s, setSearch]
  )

  const clearHandler = useCallback(() => {
    setSearch('')
    setS('')
  }, [setSearch])

  // Delay loading
  const [showLoading, setShowLoading] = useState(isDataFetching)
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (isDataFetching) {
      setShowLoading(true)
    } else {
      timeout = setTimeout(() => setShowLoading(false), 1500)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isDataFetching])

  const debounceSearch = useMemo(() => debounce(setSearch, 500), [setSearch])

  const searchChangeHandler = useCallback(
    (value: string) => {
      setS(value)
      debounceSearch(value)
    },
    [debounceSearch]
  )

  const {
    siteSettings: { kandySiteId },
  } = useSitesContext()

  const needKandySyncAction = canCreate && slug === 'affiliate_link' && kandySiteId

  return (
    <DataGridHeader
      searchValue={s}
      onSearchChange={searchChangeHandler}
      onSearchSubmit={submitHandler}
      onSearchClear={clearHandler}
      hasCreateButton={canCreate && (slug === 'affiliate_link' ? !kandySiteId : true)}
      buttonText="Create new entity"
      buttonProps={{
        component: NavLink,
        to: createPath,
      }}
      isLoading={showLoading}
      actionArea={
        <>
          {needKandySyncAction && <KandySync />}
          <BulkActionsButton entityType={entityType} />
        </>
      }
    />
  )
}

export default TableHeader
