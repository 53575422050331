// Core
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { useMutation, useQuery, useQueryClient } from 'react-query'
// Hooks
import { useGetActiveSite } from 'modules/sites'
import useDateUtils from 'core/date/use-date-utils'
// Services/Utils
import { httpService } from 'core/data'

type Props = {
  children?: never
}

type GenerationResponse = {
  status: 'idle' | 'processing'
  updatedAt: string
}

const sitemapProcessKey = (siteId: string) => `sitemap-process-${siteId}`
const getApiRoute = (siteId: string) => `/sitemap_generations/${siteId}`

const Regenerate: FC<Props> = (props) => {
  const queryClient = useQueryClient()
  const activeSite = useGetActiveSite()
  const { formateDate } = useDateUtils()
  const qKey = sitemapProcessKey(activeSite?.id!)
  const apiRoute = getApiRoute(activeSite?.id!)
  const [loading, setLoading] = useState(false)

  const processQ = useQuery(
    qKey,
    () => {
      return httpService.get<GenerationResponse>(apiRoute).then((data) => data.data)
    },
    {
      refetchInterval: 0.5 * 1000 * 60,
      refetchOnWindowFocus: true,
    }
  )

  const processM = useMutation(() => {
    return httpService.put(apiRoute)
  })

  const isLoading = useMemo(() => {
    if (
      loading ||
      processQ.isLoading ||
      processQ.data?.status === 'processing' ||
      processM.isLoading
    ) {
      return true
    }
    return false
  }, [processQ, processM, loading])

  const updatedAt = useMemo(() => {
    if (processQ.isLoading || !processQ.data) return ''
    return formateDate(processQ.data.updatedAt)
  }, [formateDate, processQ])

  const clickHandler = useCallback(async () => {
    setLoading(true)
    await processM.mutateAsync()
    await queryClient.invalidateQueries(qKey)
    setLoading(false)
  }, [processM, qKey, queryClient])

  return (
    <Box display="flex" alignItems="center">
      <Typography style={{ marginRight: 20 }}>
        <b>Last updated at: </b>
        {updatedAt}
      </Typography>
      {isLoading && <CircularProgress size={25} style={{ marginRight: 20 }} />}
      <Button disabled={isLoading} onClick={clickHandler} variant="contained" color="primary">
        Regenerate sitemap.xml
      </Button>
    </Box>
  )
}

export default Regenerate
