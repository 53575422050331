import { useMemo } from 'react'
import { useField } from 'formik'

type UseTimezoneControllerProps = {
  fieldName: string
  enabled: boolean
  originalValue: string | number
}

export const useTimezoneController = ({ fieldName }: UseTimezoneControllerProps) => {
  const timezoneFieldName = useMemo(() => {
    const base = fieldName.replace(/\.value(?!.*\.value)/, '')
    return `${base}.timezone`
  }, [fieldName])

  const [field, , helpers] = useField(timezoneFieldName)

  const selectedTimezone = field.value ?? ''

  return {
    selectedTimezone,
    changeHandler: helpers.setValue,
  }
}
