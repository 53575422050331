import React, { FC } from 'react'
import * as Yup from 'yup'
//
import { Edit } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudForm from '../crud-form'
import { useUpdateSiteSettings } from 'modules/sites/hooks'

const validationSchema = Yup.object().shape({
  dateFormat: Yup.string().required(),
  name: Yup.string().required(),
  timeFormat: Yup.string().required(),
  url: Yup.string()
    .required()
    .test('last-slash', 'Remove last trailing slash, please', (val) => val?.slice(-1) !== '/'),
  siteLocalizations: Yup.array().required(),
  network: Yup.string().required(),
  defaultLocale: Yup.string().required(),
})

const CreateSite: FC<ResourceProps> = (props) => {
  const updateSiteSettings = useUpdateSiteSettings()

  return (
    <Edit
      onSuccess={updateSiteSettings}
      validationSchema={validationSchema}
      initialData={{ systemLocalizations: ['/api/localizations/en_US'] }}
      {...props}
      crudForm={CrudForm}
    />
  )
}

export default CreateSite
