import { format } from 'date-fns'
import { useGetActiveSite } from 'modules/sites'
import { useCallback } from 'react'

export default function useDateUtils() {
  const site = useGetActiveSite()

  const formateDate = useCallback(
    (value: string | null) => {
      if (!site || !value) return ''

      const formattedDate = format(new Date(value), `${site.dateFormat} ${site.timeFormat}`)

      return formattedDate
    },
    [site]
  )

  return {
    formateDate,
  }
}
