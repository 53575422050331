// Core
import React, { FC, useCallback, useMemo } from 'react'
import { KeyboardDateTimePicker as MuiKeyboardDateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import ClearIcon from '@material-ui/icons/Clear'
import { IconButton } from '@material-ui/core'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import { parseISO, isValid } from 'date-fns'
import useFieldController from 'core/form/hooks/use-field-controller'
import { TimeZoneSelect } from './timezone-select'
import { useTimezoneController } from './use-timezone-controller'

type TProps = {
  name: string
  label: string
  readonly?: boolean
  system?: boolean
  slug?: string
  disabled?: boolean
  required?: boolean
  withTimezone?: boolean
}

const DateTimePicker: FC<TProps> = (props) => {
  const { name, label, readonly, system, disabled, required, withTimezone } = props
  const [field, { hasError, errorMsg }, { setValue }] = useFieldController(name)

  const timezoneProps = useTimezoneController({
    fieldName: name,
    enabled: !!withTimezone,
    originalValue: field.value,
  })

  const handleChange = useCallback(
    (date: MaterialUiPickersDate, val) => {
      if (!date) return null

      if (date.isValid()) {
        const zonedTime = timezoneProps.selectedTimezone
          ? zonedTimeToUtc(date.toDate(), timezoneProps.selectedTimezone)
          : date
        setValue(zonedTime.toISOString())
      } else {
        setValue(val, false)
      }
    },
    [setValue, timezoneProps.selectedTimezone]
  )

  const dateTimeValue = useMemo(() => {
    if (!field.value) return field.value
    if (!withTimezone || !timezoneProps.selectedTimezone) return field.value
    const parsedData = parseISO(field.value)
    if (!isValid(parsedData)) {
      return field.value
    }
    return utcToZonedTime(field.value, timezoneProps.selectedTimezone)
  }, [field.value, timezoneProps.selectedTimezone, withTimezone])

  const handleClr = useCallback(
    (e: any) => {
      e.stopPropagation()
      setValue(null)
    },
    [setValue]
  )

  return (
    <>
      {withTimezone && (
        <TimeZoneSelect
          value={timezoneProps.selectedTimezone}
          onChange={timezoneProps.changeHandler}
        />
      )}
      <MuiKeyboardDateTimePicker
        ampm={false}
        format="DD/MM/yyyy HH:mm"
        placeholder="DD/MM/yyyy HH:mm"
        label={label}
        fullWidth
        variant="inline"
        inputVariant="outlined"
        disabled={disabled}
        {...field}
        value={dateTimeValue}
        onChange={handleChange}
        error={hasError}
        helperText={errorMsg}
        InputProps={
          !system
            ? {
                endAdornment: (
                  <IconButton size={'small'} onClick={(e) => handleClr(e)}>
                    <ClearIcon fontSize={'small'} />
                  </IconButton>
                ),
              }
            : {}
        }
        readOnly={readonly}
        required={required}
      />
    </>
  )
}

export default DateTimePicker
