// core
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
// Hooks
import { useGetActiveSite } from 'modules/sites'
// components
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
// Services/Utils
import { httpService } from 'core/data'
import useDateUtils from 'core/date/use-date-utils'

type Props = {}

type SyncResponse = {
  status: 'idle' | 'processing'
  lastUpdatedAt: string
}

const kandySyncKey = (siteId: string) => `kandy-sync-${siteId}`
const getApiRoute = (siteId: string) => `/kandy_tracking_links_syncs/${siteId}`

const KandySync: FC<Props> = () => {
  const queryClient = useQueryClient()
  const activeSite = useGetActiveSite()
  const { formateDate } = useDateUtils()
  const qKey = kandySyncKey(activeSite?.id!)
  const apiRoute = getApiRoute(activeSite?.id!)
  const [loading, setLoading] = useState(false)

  const processQ = useQuery(
    qKey,
    () => {
      return httpService.get<SyncResponse>(apiRoute).then((data) => data.data)
    },
    {
      refetchInterval: 0.5 * 1000 * 60,
      refetchOnWindowFocus: true,
    }
  )

  const processM = useMutation(() => {
    return httpService.put(apiRoute)
  })

  const isLoading = useMemo(() => {
    if (
      loading ||
      processQ.isLoading ||
      processQ.data?.status === 'processing' ||
      processM.isLoading
    ) {
      return true
    }
    return false
  }, [processQ, processM, loading])

  const updatedAt = useMemo(() => {
    if (
      processQ.isLoading ||
      !processQ.data ||
      !activeSite ||
      processQ.data?.status === 'processing'
    )
      return ''
    return formateDate(processQ.data.lastUpdatedAt)
  }, [activeSite, formateDate, processQ.data, processQ.isLoading])

  const clickHandler = useCallback(async () => {
    setLoading(true)
    await processM.mutateAsync()
    await queryClient.invalidateQueries(qKey)
    setLoading(false)
  }, [processM, qKey, queryClient])

  return (
    <Box display="flex" alignItems="center">
      <Typography style={{ marginRight: 20 }}>
        <b>Last updated at: </b>
        {updatedAt}
      </Typography>
      {isLoading && <CircularProgress size={25} style={{ marginRight: 20 }} />}
      <Button disabled={isLoading} onClick={clickHandler} variant="contained" color="primary">
        Sync
      </Button>
    </Box>
  )
}

export default KandySync
