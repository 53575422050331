// Core
import React, { FC, useCallback, useMemo } from 'react'
import {
  FormControlLabel,
  Typography,
  IconButton,
  Grid,
  DialogActions,
  Button,
  Switch,
} from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { useFormikContext, Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
// Components
import { SwitcherField, TextField } from 'core/form'
import { SelectField } from 'core/components'
import { GroupLine } from '../../ui'
import ConditionValuesField from '../condition-values'
import SelectAttribute from '../select-attribute'
// Hooks
import { useGetData } from 'common/hooks/attributes-options'
// Utils
import { setPreviewValues } from 'common/utils/attributes-options'
import { fieldWidthData, avaiableNameable } from '../../utils'
// Types
import { Attribute } from 'core/types/attribute'

interface IInitType {
  dependsOn: string
  conditionValue: string
  fieldWidth: number
  nameable?: boolean
  hidden: boolean
  showAsColumn: boolean
  columnOrder: number
}

const initValues: IInitType = {
  dependsOn: '',
  conditionValue: '',
  fieldWidth: 12,
  nameable: false,
  hidden: false,
  showAsColumn: false,
  columnOrder: 0,
}

type Props = {
  attrData: Attribute
  openHandler: () => any
  isNested?: boolean
  parentData?: boolean
  pathToAttribute?: string
}

export const LocalOptions: FC<Props> = (props) => {
  const { t } = useTranslation()
  const widths = useMemo(() => fieldWidthData(t), [t])
  const { attrData, openHandler, isNested, parentData, pathToAttribute = '' } = props
  const isAvaiableNameable: boolean = avaiableNameable.includes(attrData.attributeType.type)
  const { values, setFieldValue } = useFormikContext<any>()
  const { options } = values

  const { newData, filteredData } = useGetData(attrData, parentData, isNested)
  const setOptionsPreview = setPreviewValues(pathToAttribute, options, newData)

  const removeConditiionHahdler = (formProps: any) => () => {
    formProps.setFieldValue('dependsOn', '')
  }

  const nameableSwitcher = (props: any) => {
    return (
      <FormControlLabel
        control={<Switch {...props} checked={props.value} />}
        label={t('attrs.as-title')}
      />
    )
  }

  const hiddenSwitcher = (props: any) => {
    return (
      <FormControlLabel
        control={<Switch {...props} checked={props.value} />}
        label={t('attrs.hide-attr')}
      />
    )
  }

  const saveHandler = useCallback(
    (_values: any) => {
      const oldOptions = options || {}
      const oldAttrOptions = options ? { ...options.attributeOptions } : null

      // set options
      setFieldValue('options', {
        ...oldOptions,
        attributeOptions: {
          ...oldAttrOptions,
          [pathToAttribute]: {
            condition: {
              dependsOn: _values.dependsOn,
              condition: 'equal',
              conditionValue: _values.conditionValue,
            },
            fieldWidth: _values.fieldWidth,
            hidden: _values.hidden,
          },
        },
      })

      // set nameable value
      const attrData = [...newData]
      const findIndex = attrData.findIndex((element: any) => element.attribute === pathToAttribute)
      if (findIndex !== -1) {
        attrData[findIndex].nameable = _values.nameable
        attrData[findIndex].showAsColumn = _values.showAsColumn
        attrData[findIndex].columnOrder = _values.columnOrder
      }

      openHandler()
    },
    [options, setFieldValue, pathToAttribute, newData, openHandler]
  )

  return (
    <Formik
      initialValues={Object.keys(setOptionsPreview).length ? setOptionsPreview : initValues}
      onSubmit={saveHandler}
    >
      {(formProps: any) => (
        <Form>
          <GroupLine>
            {filteredData && filteredData.length ? (
              <>
                <Typography variant="body2">
                  {ReactHtmlParser(
                    t('condition.show-if', {
                      name: `<strong>${attrData.name}</strong>`,
                      interpolation: { escapeValue: false },
                    })
                  )}
                </Typography>
                <SelectAttribute data={filteredData} formProps={formProps} />
                <Typography variant="body2">
                  {ReactHtmlParser(
                    t('condition.is-to', {
                      name: `<strong>${'equal'}</strong>`,
                      interpolation: { escapeValue: false },
                    })
                  )}
                </Typography>

                {formProps.values.dependsOn.length ? (
                  <>
                    <ConditionValuesField
                      dependsValue={formProps.values.dependsOn}
                      conditionValue={formProps.values.conditionValue}
                    />
                    <div style={{ padding: '20px' }}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={removeConditiionHahdler(formProps)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </>
                ) : (
                  <div style={{ width: '100%' }}>{t('attrs.choose')}</div>
                )}
              </>
            ) : (
              t('attrs.no')
            )}
          </GroupLine>

          <GroupLine>
            <SelectField
              data={widths}
              name="fieldWidth"
              label={t('attrs.field-width')}
              value={formProps.values.fieldWidth}
            />
          </GroupLine>
          <GroupLine>
            <Grid container alignItems="center">
              <Grid xs={2}>
                <SwitcherField
                  isChecked={formProps.values.showAsColumn}
                  name="showAsColumn"
                  label={t('attrs.show-as-column')}
                />
              </Grid>
              <Grid xs={2}>
                <TextField label={t('attrs.column-order')} name="columnOrder" type="number" />
              </Grid>
            </Grid>
          </GroupLine>
          <GroupLine>
            <Field
              name="hidden"
              as={hiddenSwitcher}
              placeholder={t('attrs.hide-attr')}
              value={formProps.values.hidden || false}
            />
          </GroupLine>

          {!isNested && isAvaiableNameable && (
            <GroupLine>
              <Field
                name="nameable"
                as={nameableSwitcher}
                placeholder={t('attrs.as-title')}
                value={formProps.values.nameable}
              />
            </GroupLine>
          )}
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: 'auto' }}
            >
              {t('attrs.set-option')}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}
